<template>
  <div>
    <!-- job stats -->
    <v-row class="stats" v-if="jobItems.length > 0 && $route.name === 'jobs'">
      <v-col cols="12">
        <JobStats />
      </v-col>
    </v-row>
    <!-- success alert -->
    <v-row v-if="showAlert">
      <v-col cols="4" />
      <v-col cols="4">
        <SuccessAlert
          data-cy="alert"
          v-bind:type="alertType"
          v-bind:message="alertMessage"
        />
      </v-col>
      <v-col cols="4"> </v-col>
    </v-row>
    <!-- job filters -->
    <JobFilters v-if="$route.name === 'jobs'" />
    <!-- table three dots menu -->
    <v-row dense class="mb-n5">
      <v-spacer></v-spacer>
      <v-col lg="1" md="1" sm="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="dot_menu"
              fab
              x-small
              outlined
              color="darkGrey"
              class="dots_button"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-dots-vertical</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              :disabled="selected.length === 0"
              @click="askConfirmation('')"
            >
              <v-list-item-title>Delete Selection</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-skeleton-loader
      class="mt-5"
      v-if="fetchingData"
      type="table"
    ></v-skeleton-loader>

    <!-- jobs table -->
    <v-data-table
      v-else
      id="jobs_table"
      v-model="selected"
      dense
      :search="search"
      class="px-6 mt-5"
      loading-text="loading..."
      :loading="loading"
      no-data-text="no job available"
      :headers="headers"
      :items="jobItems"
      :items-per-page="10"
      show-select
      :single-expand="singleExpand"
      item-key="_uuid"
      show-expand
      :expanded.sync="expanded"
    >
      <!-- table search function -->
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <!-- date conversion -->
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ new Date(item.createdDate).toLocaleString() }}</span>
      </template>
      <!-- job state symbols -->
      <template v-slot:[`item.state`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon v-if="item.state === 'ACTIVE'" color="#427cbe"
                >mdi-run</v-icon
              >
              <v-icon v-if="item.state === 'COMPLETED'" color="#40aa53"
                >mdi-flag-checkered</v-icon
              >
              <v-icon
                v-if="item.state === 'EXTERNALLY_TERMINATED'"
                color="#848484"
                >mdi-close-circle-outline</v-icon
              >
              <v-icon v-if="item.state === 'FAILED'" color="#f34a6a"
                >mdi-alert-outline</v-icon
              >
            </span>
          </template>
          <span>{{ item.state }}</span>
        </v-tooltip>
      </template>
      <!-- table actions -->
      <template v-slot:item.actions="{ item }">
        <!-- result page button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="show_results"
              :disabled="item.state !== 'COMPLETED'"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              x-small
              @click.native="getResultsDatas(item)"
              :to="{ name: 'results' }"
            >
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-btn>
          </template>
          <span>show results</span>
        </v-tooltip>
        <!-- copy to clipboard -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="copy"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="copyToClipboard(item)"
              x-small
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>copy job configuration</span>
        </v-tooltip>
        <!-- info box button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="show_info"
              data-cy="show_info"
              @click="info(item)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
              color="primary"
            >
              <v-icon>mdi-clipboard-text-outline</v-icon></v-btn
            >
          </template>
          <span>show job configuration</span>
        </v-tooltip>
        <!-- edit job structure -->
        <span v-if="item.structure_uuids.length <= 1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip, attrs }">
              <v-btn
                data-cy="load_to_ui"
                :disabled="item.structure_uuids.length === 0"
                color="primary"
                @click="loadToUI(item, false)"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                icon
                x-small
              >
                <v-icon>mdi-weather-cloudy-arrow-right</v-icon></v-btn
              >
            </template>
            <span>edit structure</span>
          </v-tooltip>
        </span>
        <span v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-btn
                    data-cy="load_to_ui_menu"
                    color="primary"
                    @click="prepareStructuresMenu(item)"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    icon
                    x-small
                  >
                    <v-icon>mdi-weather-cloudy-arrow-right</v-icon>
                  </v-btn>
                </template>
                <v-list dense v-if="structures.length > 0">
                  <v-list-item
                    data-cy="list_item"
                    v-for="(item, i) in structures"
                    :key="i"
                    @click="loadToUI(item, true)"
                  >
                    <v-list-item-title class="text">
                      {{ item.label }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <span>edit structure</span>
          </v-tooltip>
        </span>
        <!-- cancel running job -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="item.state !== 'ACTIVE'"
              data-cy="cancel_job"
              color="primary"
              @click="cancel(item._uuid)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
            >
              <v-icon>mdi-cancel</v-icon></v-btn
            >
          </template>
          <span>cancel running job</span>
        </v-tooltip>
        <!-- delete item -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="delete"
              color="primary"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
              @click="askConfirmation(item)"
            >
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <span>delete job</span>
        </v-tooltip>
      </template>
      <!-- foldable description -->
      <template v-slot:expanded-item="{ headers, item }">
        <td class="text" :colspan="headers.length">
          Description: {{ item.description }}
        </td>
      </template>
    </v-data-table>

    <ConfigurationInfo
      data-cy="job_info"
      ref="info"
      :job="job"
      :structures="structures"
      :jobParam="jobParam"
      :hasTabs="hasTabs"
      :copyData="copyData"
    />

    <ConfirmDialog
      :visible="showConfirmDialog"
      :selected="selected.length"
      @close="showConfirmDialog = false"
      @remove="deleteJob"
    />
  </div>
</template>

<script>
const JobStats = () => import('./JobStats.vue')
const JobFilters = () => import('./JobFilters.vue')
const ConfigurationInfo = () => import('../general/ConfigurationInfo.vue')
const ConfirmDialog = () => import('../general/ConfirmDialog.vue')
const SuccessAlert = () => import('../general/SuccessAlert.vue')

import utils from '../../appUtils/utils'

import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  components: {
    ConfigurationInfo,
    ConfirmDialog,
    SuccessAlert,
    JobStats,
    JobFilters,
  },

  data() {
    return {
      /* data-table */
      selected: [],
      search: '',
      loading: false,
      singleExpand: false,
      expanded: [],
      /* table actions */
      itemToDelete: {},
      showConfirmDialog: false,
      hasTabs: true,
      copyData: {},
      job: {},
      jobParam: {},
      structures: [],
      /* SuccessAlert component */
      showAlert: false,
      alertMessage: '',
      alertType: '',
      /* general */
      fetchingData: false,
    }
  },

  props: { loadTable: Boolean },

  methods: {
    ...mapActions('backend', ['load', 'loadById', 'delete', 'cancelProcess']),

    ...mapMutations('dynamicForms', ['createUserInputFromDataNode', 'set']),

    ...mapMutations('backend', [
      'setCurrentTopic',
      'setCurrentProcesses',
      'prepareResults',
    ]),

    cancel(_uuid) {
      this.cancelProcess(_uuid)
      this.load(['process'])
    },

    /* table actions */
    async getResultsDatas(item) {
      let job = await this.loadById(['process', item._uuid])
      let resultDatas = []
      let resultIds = Object.values(job.outputs)

      for (let _uuid of resultIds) {
        let result = await this.loadById(['data', _uuid])
        let partialSchema = this.getByLabelAndVersion([
          'schema',
          result.hqschema.label,
          result.hqschema.version,
        ])
        let schema = await this.loadById(['schema', partialSchema._uuid])
        resultDatas.push({ data: result, schema: schema })
      }
      this.prepareResults(resultDatas)
    },

    copyToClipboard(item) {
      this.showAlert = false
      this.copyData = cloneDeep(this.getById(['process', item._uuid]))
      try {
        navigator.clipboard.writeText(JSON.stringify(this.copyData, null, 2))
        this.prepareAlert('success', 'Copied successfully.')
      } catch (error) {
        this.prepareAlert('error', 'Copying failed.')
      }
    },

    async info(item) {
      this.job = await this.loadById(['process', item._uuid])
      let inputIDs = []
      for (let value of Object.values(this.job.inputs)) {
        inputIDs.push(value)
      }
      this.structures = this.getDatasByTag('structure').filter((e) =>
        inputIDs.includes(e._uuid)
      )
      this.jobParam = this.getDatasByTag('job-params').find((e) =>
        inputIDs.includes(e._uuid)
      )
      this.$refs.info.openDialog()
    },

    prepareStructuresMenu(item) {
      this.structures = this.getDatasByTag('structure').filter((e) =>
        item.structure_uuids.includes(e._uuid)
      )
    },

    async loadToUI(item, isStructure) {
      this.loading = true
      let data = {}
      if (isStructure) {
        data = this.datas.find((e) => e._uuid === item._uuid)
      } else {
        data = this.datas.find((e) => e._uuid === item.structure_uuids[0])
      }
      let structure = await this.loadById(['data', data._uuid])
      await this.createUserInputFromDataNode(structure)
      this.set(['edit', true])
      let routeName = structure.hqschema.label
      await this.$router.push({ name: routeName })
      this.loading = false
    },

    askConfirmation(item) {
      if (item !== '') {
        this.itemToDelete = item
      }
      this.showConfirmDialog = true
    },

    async deleteJob() {
      this.loading = true
      if (this.selected.length === 0) {
        this.selected.push(this.itemToDelete)
      }
      for (let job of this.selected) {
        try {
          await this.delete(['process', job._uuid])
          this.prepareAlert('success', 'Deleted successfully.')
        } catch (error) {
          this.prepareAlert('error', 'Your job could not be deleted.')
        }
      }
      this.selected = []
      await this.load(['process'])
      this.setCurrentProcesses()
      this.loading = false
      this.showConfirmDialog = false
    },

    /* general */
    prepareAlert(type, message) {
      this.alertType = type
      this.alertMessage = message
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },

    async loadScopeData() {
      await this.load(['process'])
      if (this.schemas.length === 0) {
        await this.load(['schema'])
      }
      if (this.workflows.length === 0) {
        await this.load(['workflow'])
      }
      if (this.datas.length === 0) {
        await this.load(['data'])
      }
    },
  },

  computed: {
    ...mapGetters('backend', [
      'getById',
      'getJobTable',
      'getByLabelAndVersion',
      'getDatasByTag',
    ]),

    ...mapState('backend', {
      datas: (state) => state.datas,
      schemas: (state) => state.schemas,
      currentTopic: (state) => state.currentTopic,
      currentWorkflows: (state) => state.currentWorkflows,
      workflows: (state) => state.workflows,
      alertTimeout: (state) => state.alertTimeout,
    }),

    ...mapState('dynamicForms', {
      currentStructureCard: (state) => state.currentStructureCard,
    }),

    /* data-table */
    jobItems() {
      let needsAllJobs = this.$route.name === 'jobs' ? true : false
      const jobs = utils.transformJobTableToFormattedStrings(this.getJobTable(needsAllJobs)) ?? []
      const jobsSortedByDate = jobs.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      return jobsSortedByDate
    },

    headers() {
      return [
        { text: 'Label', align: 'start', value: 'label', sortable: true },
        { text: 'Workflow', align: 'start', value: 'workflow' },
        { text: 'Structures', value: 'structureLabels', sortable: false },
        { text: 'Created Date', value: 'createdDate' },
        { text: 'State', value: 'state', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
        { text: '', value: 'data-table-expand' },
      ]
    },
  },

  watch: {
    async loadTable() {
      await this.setCurrentProcesses()
      await this.getJobTable(false)
    },
  },

  async created() {
    this.fetchingData = true
    this.loadScopeData()
    this.fetchingData = false
  },
}
</script>

<style scoped></style>
