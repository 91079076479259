var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.jobItems.length > 0 && _vm.$route.name === 'jobs')?_c('v-row',{staticClass:"stats"},[_c('v-col',{attrs:{"cols":"12"}},[_c('JobStats')],1)],1):_vm._e(),(_vm.showAlert)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('SuccessAlert',{attrs:{"data-cy":"alert","type":_vm.alertType,"message":_vm.alertMessage}})],1),_c('v-col',{attrs:{"cols":"4"}})],1):_vm._e(),(_vm.$route.name === 'jobs')?_c('JobFilters'):_vm._e(),_c('v-row',{staticClass:"mb-n5",attrs:{"dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"lg":"1","md":"1","sm":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dots_button",attrs:{"data-cy":"dot_menu","fab":"","x-small":"","outlined":"","color":"darkGrey"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.selected.length === 0},on:{"click":function($event){return _vm.askConfirmation('')}}},[_c('v-list-item-title',[_vm._v("Delete Selection")])],1)],1)],1)],1)],1),(_vm.fetchingData)?_c('v-skeleton-loader',{staticClass:"mt-5",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"px-6 mt-5",attrs:{"id":"jobs_table","dense":"","search":_vm.search,"loading-text":"loading...","loading":_vm.loading,"no-data-text":"no job available","headers":_vm.headers,"items":_vm.jobItems,"items-per-page":10,"show-select":"","single-expand":_vm.singleExpand,"item-key":"_uuid","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdDate).toLocaleString()))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.state === 'ACTIVE')?_c('v-icon',{attrs:{"color":"#427cbe"}},[_vm._v("mdi-run")]):_vm._e(),(item.state === 'COMPLETED')?_c('v-icon',{attrs:{"color":"#40aa53"}},[_vm._v("mdi-flag-checkered")]):_vm._e(),(item.state === 'EXTERNALLY_TERMINATED')?_c('v-icon',{attrs:{"color":"#848484"}},[_vm._v("mdi-close-circle-outline")]):_vm._e(),(item.state === 'FAILED')?_c('v-icon',{attrs:{"color":"#f34a6a"}},[_vm._v("mdi-alert-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.state))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"show_results","disabled":item.state !== 'COMPLETED',"color":"primary","icon":"","x-small":"","to":{ name: 'results' }},nativeOn:{"click":function($event){return _vm.getResultsDatas(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chart-areaspline")])],1)]}}],null,true)},[_c('span',[_vm._v("show results")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"copy","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.copyToClipboard(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("copy job configuration")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"show_info","data-cy":"show_info","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.info(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("show job configuration")])]),(item.structure_uuids.length <= 1)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"load_to_ui","disabled":item.structure_uuids.length === 0,"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.loadToUI(item, false)}}},'v-btn',attrs,false),Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-weather-cloudy-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("edit structure")])])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"load_to_ui_menu","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.prepareStructuresMenu(item)}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-weather-cloudy-arrow-right")])],1)]}}],null,true)},[(_vm.structures.length > 0)?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.structures),function(item,i){return _c('v-list-item',{key:i,attrs:{"data-cy":"list_item"},on:{"click":function($event){return _vm.loadToUI(item, true)}}},[_c('v-list-item-title',{staticClass:"text"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("edit structure")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.state !== 'ACTIVE',"data-cy":"cancel_job","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.cancel(item._uuid)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel running job")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"delete","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.askConfirmation(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("delete job")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"text",attrs:{"colspan":headers.length}},[_vm._v(" Description: "+_vm._s(item.description)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('ConfigurationInfo',{ref:"info",attrs:{"data-cy":"job_info","job":_vm.job,"structures":_vm.structures,"jobParam":_vm.jobParam,"hasTabs":_vm.hasTabs,"copyData":_vm.copyData}}),_c('ConfirmDialog',{attrs:{"visible":_vm.showConfirmDialog,"selected":_vm.selected.length},on:{"close":function($event){_vm.showConfirmDialog = false},"remove":_vm.deleteJob}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }